import { FC, useId } from 'react';

import dynamic from 'next/dynamic';

import { breakpoints } from '@hultafors/shared/constants';

import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import {
  DualProductBlockFragment,
  ImageLoadFragment,
} from '@hultafors/solidgear/types';

import { ButtonLink } from '../ButtonLink/ButtonLink';

import {
  ContentWrapper,
  Dash,
  DualProductBlockStyled,
  ImagesContainer,
  ImageWrapper,
  Inner,
  Label,
  Outer,
  Title,
} from './DualProductBlock.styled';

const DatoImage = dynamic(
  () =>
    import('@hultafors/shared/components').then((module) => module.DatoImage),
  { ssr: false },
);

export const DualProductBlock: FC<DualProductBlockFragment> = ({
  images,
  dualImagesLoad,
  title,
  label,
  ctaLabel,
  ctaUrl,
  fullWidth,
  textColor,
}) => {
  const { settings } = useGlobal();
  const imageId = useId();

  const sizes = `(min-width: ${breakpoints.desktop}) 50vw, 100vw`;

  function imagesMapper(image: ImageLoadFragment, i: number) {
    const key = `dual-image-${imageId}-${i}`;
    return (
      <ImageWrapper key={key}>
        <DatoImage image={image} sizes={sizes} aspectRatio={1} />
      </ImageWrapper>
    );
  }

  return (
    <DualProductBlockStyled $fullWidth={fullWidth}>
      <Outer>
        <Inner>
          <ContentWrapper $textColor={textColor?.hex}>
            {label && <Label>{label}</Label>}
            <Dash $borderColor={textColor?.hex} />
            {title && <Title type="h2">{title}</Title>}
            <ButtonLink
              disabled={false}
              href={createUrl(ctaUrl ?? '', settings)}
              className="White"
            >
              {ctaLabel}
            </ButtonLink>
          </ContentWrapper>
        </Inner>
      </Outer>
      <ImagesContainer>{dualImagesLoad?.map(imagesMapper)}</ImagesContainer>
    </DualProductBlockStyled>
  );
};
